import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import PriorityEntity from "../entity";

export default class PriorityOverviewShow extends DefaultController {
    async init() {
        this.entity = "priority";
        await super.init();
    }

    protected getEntityData(elem: any) {
        return PriorityEntity.getEntityData(elem)
    }
    bindListeners() {
    }
}